import React, { useEffect, useState, useRef, useMemo } from "react";
import { InfiniteScroll, List, NavBar, Toast } from "antd-mobile";
import { useNavigate, useLocation } from "react-router-dom";
import { getShopStatisticsUserOrder } from "../../axios";
import { getDay, getOneDayEnd, getOneDayStart } from "../../utils";
import { useSelector } from "react-redux";

export default function StatisticsUserOrder() {
    const shop = useSelector((state) => state.shop.shop);
    const isMasterShop = useMemo(() => shop._id === "6528737d3f59abb12181c477", [shop]);
    const navigate = useNavigate();
    const location = useLocation();
    const userListRef = useRef(null);
    const now = useMemo(() => Date.now(), []);
    const showTimeStr = useMemo(() => location.state?.showTimeStr || getDay(now), [location.state?.showTimeStr, now]);
    const startTime = useMemo(() => location.state?.startTime || getOneDayStart(now), [location.state?.startTime, now]);
    const endTime = useMemo(() => location.state?.endTime || getOneDayEnd(now), [location.state?.endTime, now]);
    const [hasMore, setHasMore] = useState(true);
    const pagenation = useRef({ pageNum: 0, loading: false });
    const [userList, setUserList] = useState<any>([]);
    const [scrollTop, setScrollTop] = useState(0);

    async function loadMore() {
        if(pagenation.current.loading) {
            return;
        }
        pagenation.current.loading = true;
        let query: any = {
            pageNum: pagenation.current.pageNum,
            pageSize: 20,
            startTime,
            endTime,
        };
        pagenation.current.pageNum++;
        try {
            const { data } = await getShopStatisticsUserOrder(query);
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                const { result, singleCost } = data;
                const _result = result.map((userOrderInfo: any) => {
                    const userSingleCost = singleCost.find((val) => val._id === userOrderInfo._id);
                    userOrderInfo.singleCost = userSingleCost?.total || 0; 
                    userOrderInfo.multiCost = userOrderInfo.spend - userOrderInfo.singleCost;
                    return userOrderInfo
                })
                setUserList([...userList, ..._result]);
                setHasMore(data.result.length === 20);
            }
        } catch (e) {
            Toast.show("获取失败");
        }
        pagenation.current.loading = false;
    }

    useEffect(() => {
        if (location.pathname === "/manager/statistics/user/order") {
            if (userListRef && userListRef.current) {
                setTimeout(() => {
                    userListRef.current.scrollTop = scrollTop
                }, 1)
            }
        }
    }, [location.pathname, scrollTop]);
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh"
        }}>
            <NavBar onBack={() => navigate(-1)}>
                {showTimeStr}购彩用户统计
            </NavBar>
            <div style={{
                flex: "1 1 auto",
                overflowY: "auto"
            }} ref={userListRef}>
                <List>
                    {
                        userList.map((user: any) => {
                            return (
                                <List.Item>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }} onClick={() =>{
                                        if (isMasterShop) {
                                            setScrollTop(userListRef.current?.scrollTop || 0);
                                            navigate("/manager/bill/detail", { state: { userId: user._id } })
                                        }
                                    }}>
                                        <div>{ user.user[0]?.remark || user.user[0]?.username }</div>
                                        <div style={{ width: '150px' }}>
                                            <div>购买次数：{ user.num }次</div>
                                            <div>购买金额：{ user.spend?.toFixed(2) }元</div>
                                            <div>单关金额：{ user.singleCost?.toFixed(2) }元</div>
                                            <div>串关金额：{ user.multiCost?.toFixed(2) }元</div>
                                            <div>获奖金额：{ user.bonus?.toFixed(2) }元</div>
                                        </div>
                                    </div>
                                </List.Item>
                            );
                        })
                    }
                </List>
                <InfiniteScroll hasMore={hasMore} loadMore={loadMore}/>
            </div>
        </div>
    )
}